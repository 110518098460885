<template>
  <div class="page inPage badgePage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Badge</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-badge :content="5">
          <div class="child"/>
        </lee-badge>
        <lee-badge :content="10">
          <div class="child"/>
        </lee-badge>
        <lee-badge content="Hot">
          <div class="child"/>
        </lee-badge>
        <lee-badge dot>
          <div class="child"/>
        </lee-badge>
      </lee-cell-group>
      <lee-cell-group title="最大值" inset>
        <lee-badge :content="20" :max="9">
          <div class="child"/>
        </lee-badge>
        <lee-badge :content="50" :max="20">
          <div class="child"/>
        </lee-badge>
        <lee-badge :content="200" :max="99">
          <div class="child"/>
        </lee-badge>
      </lee-cell-group>
      <lee-cell-group title="自定义颜色" inset>
        <lee-badge :content="5" color="#1989fa">
          <div class="child"/>
        </lee-badge>
        <lee-badge :content="10" color="#1989fa">
          <div class="child"/>
        </lee-badge>
        <lee-badge dot color="#1989fa">
          <div class="child"/>
        </lee-badge>
      </lee-cell-group>
      <lee-cell-group title="自定义徽标内容" inset>
        <lee-badge>
          <div class="child"/>
          <template #content>
            <lee-icon name="select"/>
          </template>
        </lee-badge>
        <lee-badge>
          <div class="child"/>
          <template #content>
            <lee-icon name="close"/>
          </template>
        </lee-badge>
        <lee-badge>
          <div class="child"/>
          <template #content>
            <lee-icon name="smile"/>
          </template>
        </lee-badge>
      </lee-cell-group>
      <lee-cell-group title="自定义徽标位置" inset>
        <lee-badge :content="10" position="top-left">
          <div class="child"/>
        </lee-badge>
        <lee-badge :content="10" position="bottom-left">
          <div class="child"/>
        </lee-badge>
        <lee-badge :content="10" position="bottom-right">
          <div class="child"/>
        </lee-badge>
      </lee-cell-group>
      <lee-cell-group title="独立展示" inset>
        <lee-badge :content="20" style="margin-right: 16px;"/>
        <lee-badge :content="200" :max="99" style="margin-right: 16px;"/>
        <lee-badge>
          <template #content>
            <lee-icon name="smile"/>
          </template>
        </lee-badge>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
</script>
<style>
.badgePage .child {
  width: 40px;
  height: 40px;
  background: var(--lee-bg-gray);
  border-radius: 4px;
}

.badgePage .inset{
  background: var(--lee-bg-white);
  padding: 16px 0;
  display: flex;
  align-items: center;
}

.badgePage .lee-badge__wrapper {
  margin-left: 16px;
}

</style>
